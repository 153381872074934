<template>
  <div class="leads-table-area dealers-table">
    <div style="width: 500px; margin: 0px auto; padding: 35px">
      <form v-on:submit.prevent="importDMS" enctype="multipart/form-data">
        <div class="add-member">
          <div class="form-row"></div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="first_name">SELECT DMS</label>
              <select class="form-control" v-model="name" required>
                <option v-for="dms in dms" :key="dms.id">
                  {{ dms.dms_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-12">
              <label for="last_name">File</label>
              <input
                type="file"
                class="form-control"
                v-on:change="onFileChange"
                required
              />
            </div>
            <button :disabled="btnLoader" type="submit" class="rem-btn">
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Import</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import $ from "jquery";
export default {
  data() {
    return {
      dms: [],
      btnLoader: false,
      name: "",
      file: "",
    };
  },
  created() {
    this.getDMSName();
  },
  methods: {
    importDMS() {
      this.btnLoader = true;
      let uploadFormData = new FormData();
      uploadFormData.append("name", this.name);
      uploadFormData.append("file", this.file);

      axios
        .post("/importdmsfile", uploadFormData, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.$toast.open({
              position: "top-right",
              message: "File Imported Successfully",
            });
            this.btnLoader = false;
            console.log(response);
          }
        })
        .catch(function (error) {
          this.btnLoader = false;
          alert(error);
        });
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },
    getDMSName() {
      axios
        .get("get/dms", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.dms = res.data.dms;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>